.projects {
  margin-top: 3rem;
  overflow-x: hidden;
}
.projects h2 {
  margin-bottom: 3.5rem;
}

.project {
  margin-bottom: 4rem;
}
.project h3 {
  color: #f9f5ff;
  text-align: center;
  margin-bottom: 7rem;
  font-size: 2.5rem;
  text-decoration: underline;
}
@media screen and (min-width: 992px) {
  .project h3 {
    margin-bottom: 0;
  }
}
.project__container {
  position: relative;
  margin: 4rem auto 6rem auto;
  width: 90%;
  background-color: #f9f5ff;
  padding: 11rem 1.3rem 2rem 1.3rem;
  color: #111111;
  border-radius: 2rem;
  text-align: center;
  font-size: 2.2rem;
  line-height: 2.6rem;
}
.project__container p:last-of-type {
  margin-top: 1rem;
}
@media screen and (min-width: 700px) {
  .project__container {
    padding-top: 16.5rem;
  }
}
@media screen and (min-width: 992px) {
  .project__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: transparent;
    color: #f9f5ff;
    padding-top: 4rem;
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    place-items: center;
  }
}
.project img {
  position: absolute;
  top: -5.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  padding: 0.1rem;
  border-radius: 2rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 65rem;
  filter: drop-shadow(0px 5px 8px #111111);
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 700px) {
  .project img {
    min-height: 20rem;
  }
}
@media screen and (min-width: 992px) {
  .project img {
    position: static;
    min-height: 35rem;
    padding: 0;
    border-radius: 0;
    padding: 1.5rem;
    background-color: rgba(204, 41, 54, 0.5);
    border-radius: 0.5rem;
    width: 100%;
    transform: none;
    margin-left: auto;
  }
  .project img:hover {
    scale: 1.2;
  }
}
.project a {
  color: #cc2936;
  width: -moz-fit-content;
  width: fit-content;
}
.project__description .highlight {
  color: #cc2936;
}
.project__description .highlight:not(:last-of-type)::after {
  content: ", ";
}
@media screen and (min-width: 992px) {
  .project {
    margin-bottom: 10rem;
  }
  .project__description {
    grid-row: 1/1;
    justify-self: center;
    align-self: center;
    z-index: 2;
    padding: 2rem;
    background-color: rgba(249, 245, 255, 0.1);
    border-radius: 10px;
    width: 100%;
  }
  .project:nth-of-type(odd) .project__link {
    grid-column: 2/2;
    justify-self: end;
    width: 90%;
  }
  .project:nth-of-type(odd) .project__link img {
    transform-origin: center right;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    max-width: none;
  }
  .project:nth-of-type(odd) .project__description {
    grid-column: 1/1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    justify-self: start;
  }
  .project:nth-of-type(even) .project__link {
    justify-self: start;
    width: 90%;
  }
  .project:nth-of-type(even) .project__link img {
    transform-origin: center left;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    max-width: none;
  }
  .project:nth-of-type(even) .project__description {
    grid-column: 2/2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    justify-self: end;
  }
}