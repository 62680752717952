.about {
  margin-top: 3rem;
}
.about__container {
  padding-top: 1.5rem;
  color: #f9f5ff;
  width: 90%;
  max-width: 215rem;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  .about__container {
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 992px) {
  .about__summary {
    font-size: 2.2rem;
    line-height: 3.5rem;
    margin-right: 5rem;
  }
}
@media screen and (min-width: 1940px) {
  .about__summary {
    line-height: 4.5rem;
  }
}
.about__languages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}
.about__languages > div {
  width: 33.3%;
}
@media screen and (min-width: 992px) {
  .about__languages {
    width: 100%;
    gap: 70px;
  }
}