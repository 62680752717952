.contact {
  margin-top: 5rem;
  color: #f9f5ff;
  font-size: 2.3rem;
  text-align: center;
}
.contact p {
  padding: 0 2rem 1rem 2rem;
}
.contact form {
  width: 80%;
  margin: 1rem auto 0 auto;
}
@media screen and (min-width: 992px) {
  .contact form {
    width: 50%;
  }
}
.contact__wrapper {
  position: relative;
}
.contact__wrapper label {
  position: absolute;
  top: 0.2rem;
  left: 1rem;
  color: #f9f5ff;
  transition: all 0.2s ease-in-out;
}
.contact__wrapper input,
.contact__wrapper textarea {
  width: 100%;
  font-size: 2rem;
  padding: 0.5rem;
  background-color: rgba(249, 245, 255, 0.2);
  color: #f9f5ff;
  border: none;
  border-radius: 0.5rem;
}
.contact__wrapper input:not(textarea),
.contact__wrapper textarea:not(textarea) {
  height: 3rem;
  margin-bottom: 3rem;
}
.contact__wrapper input:focus,
.contact__wrapper textarea:focus {
  outline: none;
}
.contact__wrapper input:not(:-moz-placeholder-shown) + label, .contact__wrapper textarea:not(:-moz-placeholder-shown) + label {
  color: #cc2936;
  top: -2.8rem;
  left: 0.2rem;
  font-size: 2.5rem;
  font-weight: 800;
}
.contact__wrapper input:focus + label, .contact__wrapper input:not(:placeholder-shown) + label,
.contact__wrapper textarea:focus + label,
.contact__wrapper textarea:not(:placeholder-shown) + label {
  color: #cc2936;
  top: -2.8rem;
  left: 0.2rem;
  font-size: 2.5rem;
  font-weight: 800;
}
.contact button {
  margin-right: 0;
}
@media screen and (min-width: 992px) {
  .contact {
    margin-bottom: 10rem;
  }
}