@import "./Shared.css";
@import-normalize;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  background-color: #111;
}

img,
video,
svg {
  width: 100%;
  height: auto;
  display: block;
}

main {
  position: relative;
}
main .particle-container {
  position: sticky;
  top: 0;
  z-index: -2;
}
main .particle {
  position: absolute;
  border-radius: 50%;
}
@keyframes particle-animation-1 {
  100% {
    transform: translate3d(48vw, 21vh, 75px);
  }
}
main .particle:nth-child(1) {
  animation: particle-animation-1 60s infinite;
  opacity: 0.3;
  height: 4px;
  width: 4px;
  animation-delay: -0.2s;
  transform: translate3d(42vw, 24vh, 18px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-2 {
  100% {
    transform: translate3d(89vw, 80vh, 81px);
  }
}
main .particle:nth-child(2) {
  animation: particle-animation-2 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -0.4s;
  transform: translate3d(31vw, 84vh, 82px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-3 {
  100% {
    transform: translate3d(59vw, 39vh, 98px);
  }
}
main .particle:nth-child(3) {
  animation: particle-animation-3 60s infinite;
  opacity: 0.3;
  height: 6px;
  width: 6px;
  animation-delay: -0.6s;
  transform: translate3d(10vw, 9vh, 93px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-4 {
  100% {
    transform: translate3d(2vw, 14vh, 76px);
  }
}
main .particle:nth-child(4) {
  animation: particle-animation-4 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -0.8s;
  transform: translate3d(24vw, 45vh, 36px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-5 {
  100% {
    transform: translate3d(23vw, 14vh, 80px);
  }
}
main .particle:nth-child(5) {
  animation: particle-animation-5 60s infinite;
  opacity: 0.3;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  transform: translate3d(73vw, 12vh, 87px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-6 {
  100% {
    transform: translate3d(7vw, 69vh, 4px);
  }
}
main .particle:nth-child(6) {
  animation: particle-animation-6 60s infinite;
  opacity: 0.3;
  height: 5px;
  width: 5px;
  animation-delay: -1.2s;
  transform: translate3d(88vw, 6vh, 75px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-7 {
  100% {
    transform: translate3d(15vw, 68vh, 31px);
  }
}
main .particle:nth-child(7) {
  animation: particle-animation-7 60s infinite;
  opacity: 0.3;
  height: 5px;
  width: 5px;
  animation-delay: -1.4s;
  transform: translate3d(9vw, 54vh, 37px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-8 {
  100% {
    transform: translate3d(83vw, 28vh, 46px);
  }
}
main .particle:nth-child(8) {
  animation: particle-animation-8 60s infinite;
  opacity: 0.3;
  height: 5px;
  width: 5px;
  animation-delay: -1.6s;
  transform: translate3d(63vw, 23vh, 17px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-9 {
  100% {
    transform: translate3d(41vw, 13vh, 40px);
  }
}
main .particle:nth-child(9) {
  animation: particle-animation-9 60s infinite;
  opacity: 0.3;
  height: 3px;
  width: 3px;
  animation-delay: -1.8s;
  transform: translate3d(16vw, 1vh, 71px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-10 {
  100% {
    transform: translate3d(45vw, 1vh, 1px);
  }
}
main .particle:nth-child(10) {
  animation: particle-animation-10 60s infinite;
  opacity: 0.3;
  height: 4px;
  width: 4px;
  animation-delay: -2s;
  transform: translate3d(52vw, 41vh, 93px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-11 {
  100% {
    transform: translate3d(14vw, 71vh, 69px);
  }
}
main .particle:nth-child(11) {
  animation: particle-animation-11 60s infinite;
  opacity: 0.3;
  height: 3px;
  width: 3px;
  animation-delay: -2.2s;
  transform: translate3d(59vw, 25vh, 73px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-12 {
  100% {
    transform: translate3d(6vw, 26vh, 62px);
  }
}
main .particle:nth-child(12) {
  animation: particle-animation-12 60s infinite;
  opacity: 0.3;
  height: 3px;
  width: 3px;
  animation-delay: -2.4s;
  transform: translate3d(38vw, 61vh, 54px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-13 {
  100% {
    transform: translate3d(27vw, 8vh, 7px);
  }
}
main .particle:nth-child(13) {
  animation: particle-animation-13 60s infinite;
  opacity: 0.3;
  height: 3px;
  width: 3px;
  animation-delay: -2.6s;
  transform: translate3d(63vw, 59vh, 8px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-14 {
  100% {
    transform: translate3d(59vw, 50vh, 70px);
  }
}
main .particle:nth-child(14) {
  animation: particle-animation-14 60s infinite;
  opacity: 0.3;
  height: 4px;
  width: 4px;
  animation-delay: -2.8s;
  transform: translate3d(50vw, 5vh, 32px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-15 {
  100% {
    transform: translate3d(18vw, 11vh, 75px);
  }
}
main .particle:nth-child(15) {
  animation: particle-animation-15 60s infinite;
  opacity: 0.3;
  height: 5px;
  width: 5px;
  animation-delay: -3s;
  transform: translate3d(16vw, 23vh, 38px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-16 {
  100% {
    transform: translate3d(50vw, 71vh, 93px);
  }
}
main .particle:nth-child(16) {
  animation: particle-animation-16 60s infinite;
  opacity: 0.3;
  height: 3px;
  width: 3px;
  animation-delay: -3.2s;
  transform: translate3d(6vw, 1vh, 61px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-17 {
  100% {
    transform: translate3d(71vw, 29vh, 13px);
  }
}
main .particle:nth-child(17) {
  animation: particle-animation-17 60s infinite;
  opacity: 0.3;
  height: 3px;
  width: 3px;
  animation-delay: -3.4s;
  transform: translate3d(37vw, 6vh, 26px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-18 {
  100% {
    transform: translate3d(17vw, 76vh, 91px);
  }
}
main .particle:nth-child(18) {
  animation: particle-animation-18 60s infinite;
  opacity: 0.3;
  height: 5px;
  width: 5px;
  animation-delay: -3.6s;
  transform: translate3d(28vw, 1vh, 24px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-19 {
  100% {
    transform: translate3d(74vw, 34vh, 2px);
  }
}
main .particle:nth-child(19) {
  animation: particle-animation-19 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -3.8s;
  transform: translate3d(90vw, 76vh, 77px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-20 {
  100% {
    transform: translate3d(8vw, 75vh, 85px);
  }
}
main .particle:nth-child(20) {
  animation: particle-animation-20 60s infinite;
  opacity: 0.3;
  height: 4px;
  width: 4px;
  animation-delay: -4s;
  transform: translate3d(70vw, 49vh, 5px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-21 {
  100% {
    transform: translate3d(77vw, 72vh, 87px);
  }
}
main .particle:nth-child(21) {
  animation: particle-animation-21 60s infinite;
  opacity: 0.3;
  height: 3px;
  width: 3px;
  animation-delay: -4.2s;
  transform: translate3d(80vw, 88vh, 17px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-22 {
  100% {
    transform: translate3d(34vw, 79vh, 81px);
  }
}
main .particle:nth-child(22) {
  animation: particle-animation-22 60s infinite;
  opacity: 0.3;
  height: 4px;
  width: 4px;
  animation-delay: -4.4s;
  transform: translate3d(73vw, 15vh, 85px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-23 {
  100% {
    transform: translate3d(74vw, 9vh, 87px);
  }
}
main .particle:nth-child(23) {
  animation: particle-animation-23 60s infinite;
  opacity: 0.3;
  height: 6px;
  width: 6px;
  animation-delay: -4.6s;
  transform: translate3d(46vw, 9vh, 57px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-24 {
  100% {
    transform: translate3d(7vw, 84vh, 56px);
  }
}
main .particle:nth-child(24) {
  animation: particle-animation-24 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -4.8s;
  transform: translate3d(6vw, 52vh, 21px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-25 {
  100% {
    transform: translate3d(18vw, 39vh, 100px);
  }
}
main .particle:nth-child(25) {
  animation: particle-animation-25 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -5s;
  transform: translate3d(10vw, 67vh, 97px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-26 {
  100% {
    transform: translate3d(49vw, 37vh, 46px);
  }
}
main .particle:nth-child(26) {
  animation: particle-animation-26 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -5.2s;
  transform: translate3d(70vw, 61vh, 12px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-27 {
  100% {
    transform: translate3d(54vw, 30vh, 20px);
  }
}
main .particle:nth-child(27) {
  animation: particle-animation-27 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -5.4s;
  transform: translate3d(51vw, 61vh, 70px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-28 {
  100% {
    transform: translate3d(46vw, 7vh, 87px);
  }
}
main .particle:nth-child(28) {
  animation: particle-animation-28 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -5.6s;
  transform: translate3d(29vw, 48vh, 55px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-29 {
  100% {
    transform: translate3d(45vw, 42vh, 16px);
  }
}
main .particle:nth-child(29) {
  animation: particle-animation-29 60s infinite;
  opacity: 0.3;
  height: 2px;
  width: 2px;
  animation-delay: -5.8s;
  transform: translate3d(75vw, 72vh, 51px);
  background: rgba(249, 245, 255, 0.5);
}
@keyframes particle-animation-30 {
  100% {
    transform: translate3d(5vw, 87vh, 24px);
  }
}
main .particle:nth-child(30) {
  animation: particle-animation-30 60s infinite;
  opacity: 0.3;
  height: 6px;
  width: 6px;
  animation-delay: -6s;
  transform: translate3d(29vw, 43vh, 72px);
  background: rgba(249, 245, 255, 0.5);
}