@import "./Shared.css";
.landing {
  background-color: #111111;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #aaa1a1;
  overflow-x: hidden;
  text-align: center;
}
.landing h1 span {
  display: inline-block;
  font-size: 4rem;
  margin-top: 6rem;
}
.landing #tsparticles {
  height: 100%;
  overflow: hidden;
}
.landing a {
  background-color: transparent;
  border: 1px solid #cc2936;
  border-radius: 20px;
  color: #f9f5ff;
  padding: 1.5rem;
  margin-top: 3.5rem;
  margin-bottom: 5rem;
  font-size: 2rem;
  z-index: 1;
  text-decoration: none;
}
.landing a:hover {
  cursor: pointer;
  translate: 0 -50px;
  box-shadow: 0px 10px 15px -6px #cc2936;
}
.landing a::after {
  content: none;
}
.landing a svg {
  width: -moz-fit-content;
  width: fit-content;
  transform: translateY(3px);
}
@media screen and (min-width: 500px) {
  .landing a {
    margin-top: 6rem;
  }
}
@media screen and (min-width: 500px) {
  .landing {
    font-size: 4.5rem;
  }
}
.landing__languages {
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 90%;
  max-width: 1000px;
}
.landing__languages > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.landing__skill {
  padding: 0.2rem;
  border-radius: 100%;
  margin-top: 1.5rem;
  width: 20%;
}
.landing__skill .skill__content {
  margin-top: 1.5rem;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 80px;
}
.landing__skill .skill__content img {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100px;
  height: auto;
}
@media screen and (min-width: 573px) {
  .landing__skill .skill__content {
    margin-top: 5rem;
  }
}
@media screen and (min-width: 573px) {
  .landing__skill {
    width: 14%;
  }
}